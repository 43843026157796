export default {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.136038,33.440305 ]
            },
            "properties": {
            "Mine Name":"Oak Grove Mine",
            "MSHA ID Number":"100851",
            "Coal Mine Operator":"Murray Oak Grove Coal LLC",
            "Controller":"Robert Murray",
            "County":"Jefferson",
            "State":"AL",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":1364382,
            "Latest annual methane emissions (tCO2e) (Low)":724580,
            "Latest annual methane emissions (tCO2e) (High)":724580,
            "Year of latest emissions data":"2016",
            "Operator Start Date":"4/30/2019",
            "Abandonment Date":"n/a",
            "Address":"8800 OAK GROVE MINE ROAD ADGER, AL, 35006",
            "Destruction devices used":"Engine (2)",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.3089906,33.2612942 ]
            },
            "properties": {
            "Mine Name":"No. 4 Mine",
            "MSHA ID Number":"101247",
            "Coal Mine Operator":"Warrior Met Coal Mining LLC",
            "Controller":"Warrior Met Coal Intermediate Holdco LLC",
            "County":"Tuscaloosa",
            "State":"AL",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":2221242,
            "Latest annual methane emissions (tCO2e) (Low)":3873960,
            "Latest annual methane emissions (tCO2e) (High)":3873960,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"3/31/2016",
            "Abandonment Date":"n/a",
            "Address":"16243 Hwy 216 Brookwood, AL, 35444",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.3032108,33.5197657 ]
            },
            "properties": {
            "Mine Name":"Shoal Creek Mine",
            "MSHA ID Number":"102901",
            "Coal Mine Operator":"Peabody Southeast Mining LLC",
            "Controller":"Peabody Energy",
            "County":"Walker",
            "State":"AL",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":1868840,
            "Latest annual methane emissions (tCO2e) (Low)":541570,
            "Latest annual methane emissions (tCO2e) (High)":541570,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"12/4/2018",
            "Abandonment Date":"n/a",
            "Address":"654 Camp Creek Portal Rd Oakman, AL, 35579",
            "Destruction devices used":"Engine (3)",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -107.4444888,38.9282981 ]
            },
            "properties": {
            "Mine Name":"West Elk Mine",
            "MSHA ID Number":"503672",
            "Coal Mine Operator":"Mountain Coal Company LLC",
            "Controller":"Arch Resources Inc.",
            "County":"Gunnison",
            "State":"CO",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":4079345,
            "Latest annual methane emissions (tCO2e) (Low)":291648,
            "Latest annual methane emissions (tCO2e) (High)":291648,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"3/23/1998",
            "Abandonment Date":"n/a",
            "Address":"5174 Highway 133 Somerset, CO, 81434",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -106.9646061,40.2760839 ]
            },
            "properties": {
            "Mine Name":"Foidel Creek Mine",
            "MSHA ID Number":"503836",
            "Coal Mine Operator":"Peabody Twentymile Mining LLC",
            "Controller":"Peabody Energy",
            "County":"Routt",
            "State":"CO",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":2543911,
            "Latest annual methane emissions (tCO2e) (Low)":42789,
            "Latest annual methane emissions (tCO2e) (High)":42789,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"6/30/1999",
            "Abandonment Date":"n/a",
            "Address":"29515 Route County Road #27 Oak Creek, CO, 80467",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -88.7612084,38.0305542 ]
            },
            "properties": {
            "Mine Name":"MC No. 1 Mine",
            "MSHA ID Number":"1103189",
            "Coal Mine Operator":"M-Class Mining LLC",
            "Controller":"Foresight Energy Labor LLC",
            "County":"Franklin",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":12793896,
            "Latest annual methane emissions (tCO2e) (Low)":972861,
            "Latest annual methane emissions (tCO2e) (High)":972861,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"3/26/2008",
            "Abandonment Date":"n/a",
            "Address":"11351 N. Thompsonville Rd. Macedonia, IL, 62860 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -88.6845019,38.1981041 ]
            },
            "properties": {
            "Mine Name":"No. 1 Mine",
            "MSHA ID Number":"1103203",
            "Coal Mine Operator":"Hamilton County Coal LLC",
            "Controller":"Alliance Resource Partners LP",
            "County":"Hamilton",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":5889105,
            "Latest annual methane emissions (tCO2e) (Low)":676990,
            "Latest annual methane emissions (tCO2e) (High)":676990,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"8/1/2015",
            "Abandonment Date":"n/a",
            "Address":"Dahlgren, IL, 62828",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -89.8638283,39.2058587 ]
            },
            "properties": {
            "Mine Name":"Shay No. 1 Mine",
            "MSHA ID Number":"1100726",
            "Coal Mine Operator":"MaRyan Mining LLC",
            "Controller":"Foresight Energy Labor LLC",
            "County":"Macoupin",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Non-Producing Active",
            "2019 coal production (tons)":1769822,
            "Latest annual methane emissions (tCO2e) (Low)":56685,
            "Latest annual methane emissions (tCO2e) (High)":56685,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/22/2009",
            "Abandonment Date":"n/a",
            "Address":"14300 Brushy Mound Road Carlinville, IL, 62626 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -89.5892123,38.0719761 ]
            },
            "properties": {
            "Mine Name":"Prairie Eagle-Underground",
            "MSHA ID Number":"1103147",
            "Coal Mine Operator":"Knight Hawk Coal LLC",
            "Controller":"Arch Coal Inc.; Franklin D. Robertson",
            "County":"Perry",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":4095032,
            "Latest annual methane emissions (tCO2e) (Low)":73308,
            "Latest annual methane emissions (tCO2e) (High)":73308,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"4/27/2005",
            "Abandonment Date":"n/a",
            "Address":"7290 County Line Road Cutler, IL, 62238 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -89.6221605,38.1868599 ]
            },
            "properties": {
            "Mine Name":"Gateway North Mine",
            "MSHA ID Number":"1103235",
            "Coal Mine Operator":"Peabody Gateway North Mining LLC",
            "Controller":"Peabody Energy",
            "County":"Randolph",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":3033779,
            "Latest annual methane emissions (tCO2e) (Low)":18680,
            "Latest annual methane emissions (tCO2e) (High)":18680,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"9/24/2013",
            "Abandonment Date":"n/a",
            "Address":"12968 State Route 13 Coulterville, IL, 62237",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -88.3908686,37.775924 ]
            },
            "properties": {
            "Mine Name":"Wildcat Hills Mine",
            "MSHA ID Number":"1103156",
            "Coal Mine Operator":"Peabody Midwest Mining LLC",
            "Controller":"Peabody Energy",
            "County":"Saline",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Temporarily Idle",
            "2019 coal production (tons)":1416697,
            "Latest annual methane emissions (tCO2e) (Low)":104830,
            "Latest annual methane emissions (tCO2e) (High)":104830,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"11/22/2005",
            "Abandonment Date":"n/a",
            "Address":"115 Grayson Lane Eldorado, IL, 62930 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -89.4470729,40.005486 ]
            },
            "properties": {
            "Mine Name":"Viper Mine",
            "MSHA ID Number":"1102664",
            "Coal Mine Operator":"ICG Illinois LLC",
            "Controller":"Arch Resources Inc.",
            "County":"Sangamon",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":1608026,
            "Latest annual methane emissions (tCO2e) (Low)":87595,
            "Latest annual methane emissions (tCO2e) (High)":87595,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"9/30/2004",
            "Abandonment Date":"n/a",
            "Address":"781 600TH ST ELKHART, IL, 62634 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -88.8318899,37.8374608 ]
            },
            "properties": {
            "Mine Name":"Mach No. 1 Mine",
            "MSHA ID Number":"1103141",
            "Coal Mine Operator":"Mach Mining LLC",
            "Controller":"Foresight Energy Labor LLC",
            "County":"Williamson",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":5174361,
            "Latest annual methane emissions (tCO2e) (Low)":503463,
            "Latest annual methane emissions (tCO2e) (High)":503463,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"7/7/2005",
            "Abandonment Date":"n/a",
            "Address":"16468 Liberty School Road  Marion, IL, 62959 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.433876,38.3379592 ]
            },
            "properties": {
            "Mine Name":"Francisco Underground Pit",
            "MSHA ID Number":"1202295",
            "Coal Mine Operator":"Peabody Midwest Mining LLC",
            "Controller":"Peabody Energy",
            "County":"Gibson",
            "State":"IN",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":1954097,
            "Latest annual methane emissions (tCO2e) (Low)":175473,
            "Latest annual methane emissions (tCO2e) (High)":175473,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"9/1/2000",
            "Abandonment Date":"n/a",
            "Address":"1225 North 725 E Francisco, IN, 47649 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.5947296,38.3702289 ]
            },
            "properties": {
            "Mine Name":"Gibson South",
            "MSHA ID Number":"1202388",
            "Coal Mine Operator":"Gibson County Coal, LLC",
            "Controller":"Alliance Resource Partners LP",
            "County":"Gibson",
            "State":"IN",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":5412233,
            "Latest annual methane emissions (tCO2e) (Low)":955831,
            "Latest annual methane emissions (tCO2e) (High)":955831,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"8/21/2006",
            "Abandonment Date":"n/a",
            "Address":"RR #3 Lyles Station Rd. Princeton, IN, 47670 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.4155417,38.8585463 ]
            },
            "properties": {
            "Mine Name":"Oaktown Fuels Mine No. 1",
            "MSHA ID Number":"1202394",
            "Coal Mine Operator":"Sunrise Coal LLC",
            "Controller":"Hallador Energy Company",
            "County":"Knox",
            "State":"IN",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":4166561,
            "Latest annual methane emissions (tCO2e) (Low)":538140,
            "Latest annual methane emissions (tCO2e) (High)":538140,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"8/30/2014",
            "Abandonment Date":"n/a",
            "Address":"12661 North Agricare Road Oaktown, IN, 47561 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.4155417,38.8585463 ]
            },
            "properties": {
            "Mine Name":"Oaktown Fuels Mine No. 2",
            "MSHA ID Number":"1202418",
            "Coal Mine Operator":"Sunrise Coal LLC",
            "Controller":"Hallador Energy Company",
            "County":"Knox",
            "State":"IN",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":2297881,
            "Latest annual methane emissions (tCO2e) (Low)":490597,
            "Latest annual methane emissions (tCO2e) (High)":490597,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"8/30/2014",
            "Abandonment Date":"n/a",
            "Address":"12661 North Agricare Road Oaktown, IN, 47561 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.6122354,37.383829 ]
            },
            "properties": {
            "Mine Name":"Cardinal",
            "MSHA ID Number":"1517216",
            "Coal Mine Operator":"Warrior Coal LLC",
            "Controller":"Alliance Resource Partners LP",
            "County":"Hopkins",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":3664567,
            "Latest annual methane emissions (tCO2e) (Low)":313521,
            "Latest annual methane emissions (tCO2e) (High)":313521,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/27/2001",
            "Abandonment Date":"n/a",
            "Address":"57 J.E Ellis Rd. Madisonville, KY, 42431 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.052806,37.2950454 ]
            },
            "properties": {
            "Mine Name":"Pride",
            "MSHA ID Number":"1519744",
            "Coal Mine Operator":"The Muhlenberg County Coal Company LLC",
            "Controller":"Western Kentucky Consolidated Resources LLC",
            "County":"Muhlenberg",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":1982671,
            "Latest annual methane emissions (tCO2e) (Low)":78485,
            "Latest annual methane emissions (tCO2e) (High)":78485,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"2/14/2018",
            "Abandonment Date":"n/a",
            "Address":"2802 River Haul Road Central City, KY, 42330 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.2366346,37.3534442 ]
            },
            "properties": {
            "Mine Name":"Paradise No. 9",
            "MSHA ID Number":"1517741",
            "Coal Mine Operator":"KenAmerican Resources Inc.",
            "Controller":"Robert Murray",
            "County":"Muhlenberg",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Non-Producing Active",
            "2019 coal production (tons)":190375,
            "Latest annual methane emissions (tCO2e) (Low)":212154,
            "Latest annual methane emissions (tCO2e) (High)":212154,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"12/1/1995",
            "Abandonment Date":"n/a",
            "Address":"297-A State Route 2551 Bremen, KY, 42325 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.0620525,37.4239123 ]
            },
            "properties": {
            "Mine Name":"Genesis",
            "MSHA ID Number":"1519535",
            "Coal Mine Operator":"The Western Kentucky Coal Company",
            "Controller":"Western Kentucky Consolidated Resources LLC",
            "County":"Ohio",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Non-Producing Active",
            "2019 coal production (tons)":2007644,
            "Latest annual methane emissions (tCO2e) (Low)":60390,
            "Latest annual methane emissions (tCO2e) (High)":60390,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"2/14/2018",
            "Abandonment Date":"n/a",
            "Address":"175 Matanzas Road Centertown, KY, 42328 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -83.2334308,37.2762324 ]
            },
            "properties": {
            "Mine Name":"No. 77",
            "MSHA ID Number":"1509636",
            "Coal Mine Operator":"Blue Diamond Coal Co.",
            "Controller":"Blackhawk Mining LLC",
            "County":"Perry",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":348538,
            "Latest annual methane emissions (tCO2e) (Low)":31783,
            "Latest annual methane emissions (tCO2e) (High)":31783,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"9/4/2014",
            "Abandonment Date":"n/a",
            "Address":"1021 Tori Drive Hazard, KY, 41701 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -83.1560285,37.2191037 ]
            },
            "properties": {
            "Mine Name":"E4-1",
            "MSHA ID Number":"1518565",
            "Coal Mine Operator":"Jarisa Inc.",
            "Controller":"Paul M Hogg",
            "County":"Perry",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":120122,
            "Latest annual methane emissions (tCO2e) (Low)":125982,
            "Latest annual methane emissions (tCO2e) (High)":125982,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"3/9/2020",
            "Abandonment Date":"n/a",
            "Address":"4200 S. Hwy 15 Hazard, KY, 40701 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -83.1833069,37.236377 ]
            },
            "properties": {
            "Mine Name":"E4-2",
            "MSHA ID Number":"1519015",
            "Coal Mine Operator":"Perry County Resources",
            "Controller":"?",
            "County":"Perry",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Non-Producing Active",
            "2019 coal production (tons)":179266,
            "Latest annual methane emissions (tCO2e) (Low)":93919,
            "Latest annual methane emissions (tCO2e) (High)":93919,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"6/30/2006",
            "Abandonment Date":"n/a",
            "Address":"1845 S. KY HWY 15 Hazard, KY, 41701 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.492904,37.5914701 ]
            },
            "properties": {
            "Mine Name":"Mine No. 4",
            "MSHA ID Number":"1519515",
            "Coal Mine Operator":"Excel Mining",
            "Controller":"Alliance Resource Partners LP",
            "County":"Pike",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Non-Producing Active",
            "2019 coal production (tons)":1050832,
            "Latest annual methane emissions (tCO2e) (Low)":78121,
            "Latest annual methane emissions (tCO2e) (High)":78121,
            "Year of latest emissions data":"2016",
            "Operator Start Date":"7/16/2010",
            "Abandonment Date":"n/a",
            "Address":"4126 Hwy 194 West Pikeville, KY, 41501 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.4817465,37.580092 ]
            },
            "properties": {
            "Mine Name":"Mine No. 15",
            "MSHA ID Number":"1518775",
            "Coal Mine Operator":"McCoy Elkhorn Coal Co.",
            "Controller":"American Resources Corporation",
            "County":"Pike",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Temporarily Idle",
            "2019 coal production (tons)":130176,
            "Latest annual methane emissions (tCO2e) (Low)":72698,
            "Latest annual methane emissions (tCO2e) (High)":72698,
            "Year of latest emissions data":"2014",
            "Operator Start Date":"2/17/2016",
            "Abandonment Date":"n/a",
            "Address":"2540 St. Hwy. 194 East Meta, KY, 41501 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.3291531,40.6593265 ]
            },
            "properties": {
            "Mine Name":"Parkwood Mine",
            "MSHA ID Number":"3608785",
            "Coal Mine Operator":"Rosebud Mining Company",
            "Controller":"J Clifford Forrest III",
            "County":"Armstrong",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":107242,
            "Latest annual methane emissions (tCO2e) (Low)":10628,
            "Latest annual methane emissions (tCO2e) (High)":10628,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"9/19/2011",
            "Abandonment Date":"n/a",
            "Address":"785 Overview Drive Shelocta, PA, 15774 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.5641056,40.7389247 ]
            },
            "properties": {
            "Mine Name":"Logansport Mine",
            "MSHA ID Number":"3608841",
            "Coal Mine Operator":"Rosebud Mining Company",
            "Controller":"J Clifford Forrest III",
            "County":"Armstrong",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Non-Producing Active",
            "2019 coal production (tons)":701,
            "Latest annual methane emissions (tCO2e) (Low)":10490,
            "Latest annual methane emissions (tCO2e) (High)":10490,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/1/2000",
            "Abandonment Date":"n/a",
            "Address":"1000 Logansport Road Ford City, PA, 16226 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.1949216,39.8905093 ]
            },
            "properties": {
            "Mine Name":"Emerald Mine No. 1",
            "MSHA ID Number":"3605466",
            "Coal Mine Operator":"Emerald Contura, LLC",
            "Controller":"Contura Energy Inc.",
            "County":"Greene",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Non-Producing Active",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":256922,
            "Latest annual methane emissions (tCO2e) (High)":256922,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"7/26/2016",
            "Abandonment Date":"n/a",
            "Address":"212 Mine Road Waynesburg, PA, 15370 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.1642811,39.7959449 ]
            },
            "properties": {
            "Mine Name":"Cumberland Mine",
            "MSHA ID Number":"3605018",
            "Coal Mine Operator":"Cumberland Contura LLC",
            "Controller":"Contura Energy Inc.",
            "County":"Greene",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":7490061,
            "Latest annual methane emissions (tCO2e) (Low)":1201613,
            "Latest annual methane emissions (tCO2e) (High)":1201613,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"7/26/2016",
            "Abandonment Date":"n/a",
            "Address":"855 Kirby Road Waynesburg, PA, 15370 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.4721948,39.9177394 ]
            },
            "properties": {
            "Mine Name":"Bailey Mine",
            "MSHA ID Number":"3607230",
            "Coal Mine Operator":"Consol Pennsylvania Coal Company LLC",
            "Controller":"CONSOL Energy Inc.",
            "County":"Greene",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":10185898,
            "Latest annual methane emissions (tCO2e) (Low)":2904520,
            "Latest annual methane emissions (tCO2e) (High)":2904520,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"11/18/2009",
            "Abandonment Date":"n/a",
            "Address":"192 Crabapple Rd. Wind Ridge, PA, 15380 ",
            "Destruction devices used":"Engine (2)",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.4771472,39.896738 ]
            },
            "properties": {
            "Mine Name":"Harvey Mine",
            "MSHA ID Number":"3610045",
            "Coal Mine Operator":"Consol Pennsylvania Coal Company LLC",
            "Controller":"CONSOL Energy Inc.",
            "County":"Greene",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":5023781,
            "Latest annual methane emissions (tCO2e) (Low)":1538599,
            "Latest annual methane emissions (tCO2e) (High)":1538599,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/1/2014",
            "Abandonment Date":"n/a",
            "Address":"Richhill, PA, 15380",
            "Destruction devices used":"Engine (6)",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.132144,40.575913 ]
            },
            "properties": {
            "Mine Name":"Lowry Mine",
            "MSHA ID Number":"3609287",
            "Coal Mine Operator":"Rosebud Mining Company",
            "Controller":"J Clifford Forrest III",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":75872,
            "Latest annual methane emissions (tCO2e) (Low)":44428,
            "Latest annual methane emissions (tCO2e) (High)":44428,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/25/2005",
            "Abandonment Date":"n/a",
            "Address":"2389 South Sixth Street Indiana, PA, 15701 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -78.9126988,40.6240484 ]
            },
            "properties": {
            "Mine Name":"Heilwood",
            "MSHA ID Number":"3609407",
            "Coal Mine Operator":"Rosebud Mining Company",
            "Controller":"J Clifford Forrest III",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":238398,
            "Latest annual methane emissions (tCO2e) (Low)":209750,
            "Latest annual methane emissions (tCO2e) (High)":209750,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"6/5/2008",
            "Abandonment Date":"n/a",
            "Address":"6314 Route 403 Hwy S Heilwood, PA, 15745 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.245652,40.6647626 ]
            },
            "properties": {
            "Mine Name":"Crooked Creek Mine",
            "MSHA ID Number":"3609972",
            "Coal Mine Operator":"Rosebud Mining Company",
            "Controller":"J Clifford Forrest III",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":633775,
            "Latest annual methane emissions (tCO2e) (Low)":108000,
            "Latest annual methane emissions (tCO2e) (High)":108000,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"12/3/2011",
            "Abandonment Date":"n/a",
            "Address":"1875 Creekside Road Indiana, PA, 15701 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.352618,40.0761594 ]
            },
            "properties": {
            "Mine Name":"Enlow Fork Mine",
            "MSHA ID Number":"3607416",
            "Coal Mine Operator":"Consol Pennsylvania Coal Company LLC",
            "Controller":"CONSOL Energy Inc.",
            "County":"Washington",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":10043384,
            "Latest annual methane emissions (tCO2e) (Low)":2283806,
            "Latest annual methane emissions (tCO2e) (High)":2283806,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"11/23/2009",
            "Abandonment Date":"n/a",
            "Address":"2041 Pleasant Grove Rd. Claysville, PA, 15323 ",
            "Destruction devices used":"Engine (1)",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.05208,39.199239 ]
            },
            "properties": {
            "Mine Name":"Sentinel Mine",
            "MSHA ID Number":"4604168",
            "Coal Mine Operator":"Wolf Run Mining LLC",
            "Controller":"Arch Resources Inc.",
            "County":"Barbour",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":1106710,
            "Latest annual methane emissions (tCO2e) (Low)":550501,
            "Latest annual methane emissions (tCO2e) (High)":550501,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"6/7/2002",
            "Abandonment Date":"n/a",
            "Address":"21550 BARBOUR COUNTY HIGHWAY PHILIPPI, WV, 26416 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.6029407,38.1477554 ]
            },
            "properties": {
            "Mine Name":"Winchester Peerless Rachel Mine",
            "MSHA ID Number":"4609258",
            "Coal Mine Operator":"Kanawha Eagle Mining LLC",
            "Controller":"Blackhawk Mining LLC",
            "County":"Boone",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":715708,
            "Latest annual methane emissions (tCO2e) (Low)":25182,
            "Latest annual methane emissions (tCO2e) (High)":25182,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"10/26/2015",
            "Abandonment Date":"n/a",
            "Address":"235 Joes Branch Road Comfort, WV, 25049 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.5304668,38.0913003 ]
            },
            "properties": {
            "Mine Name":"American Eagle Mine",
            "MSHA ID Number":"4605437",
            "Coal Mine Operator":"Panther Creek Mining LLC",
            "Controller":"Blackhawk Mining LLC",
            "County":"Kanawha",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Non-Producing Active",
            "2019 coal production (tons)":1271954,
            "Latest annual methane emissions (tCO2e) (Low)":267035,
            "Latest annual methane emissions (tCO2e) (High)":267035,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"10/26/2015",
            "Abandonment Date":"n/a",
            "Address":"200 Remington Coal Lane, Coal Fork Hollow Cabin Creek, WV, 25035 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.8046726,37.9101396 ]
            },
            "properties": {
            "Mine Name":"Mountaineer II Mine",
            "MSHA ID Number":"4609029",
            "Coal Mine Operator":"Mingo Logan Coal LLC",
            "Controller":"Arch Resources Inc.",
            "County":"Logan",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":1256784,
            "Latest annual methane emissions (tCO2e) (Low)":556951,
            "Latest annual methane emissions (tCO2e) (High)":556951,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"3/25/2004",
            "Abandonment Date":"n/a",
            "Address":"State Route 17, Mountain Laurel Drive Sharples, WV, 25183 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.3544457,39.6814116 ]
            },
            "properties": {
            "Mine Name":"Harrison County Mine",
            "MSHA ID Number":"4601318",
            "Coal Mine Operator":"The Harrison County Coal Company",
            "Controller":"Robert E. Murray",
            "County":"Marion",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":6808868,
            "Latest annual methane emissions (tCO2e) (Low)":1471251,
            "Latest annual methane emissions (tCO2e) (High)":1471251,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/4/2016",
            "Abandonment Date":"n/a",
            "Address":"71 Camp Run Rd Mannington, WV, 26582 ",
            "Destruction devices used":"Engine (1)",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.3600257,39.5891142 ]
            },
            "properties": {
            "Mine Name":"Marion County Mine",
            "MSHA ID Number":"4601433",
            "Coal Mine Operator":"The Marion County Coal Company",
            "Controller":"Robert E. Murray",
            "County":"Marion",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":5962845,
            "Latest annual methane emissions (tCO2e) (Low)":1670362,
            "Latest annual methane emissions (tCO2e) (High)":1670362,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/4/2016",
            "Abandonment Date":"n/a",
            "Address":"151 Jonny Cake Branch Rd Metz, WV, 26585 ",
            "Destruction devices used":"Engine (1)",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.5494007,39.9994074 ]
            },
            "properties": {
            "Mine Name":"Ohio County Mine",
            "MSHA ID Number":"4601436",
            "Coal Mine Operator":"The Ohio County Coal Company",
            "Controller":"Robert E. Murray",
            "County":"Marshall",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":6600582,
            "Latest annual methane emissions (tCO2e) (Low)":600498,
            "Latest annual methane emissions (tCO2e) (High)":600498,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/4/2016",
            "Abandonment Date":"n/a",
            "Address":"Dallas, WV, 26036",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.5979885,39.8215273 ]
            },
            "properties": {
            "Mine Name":"Marshall County Mine",
            "MSHA ID Number":"4601437",
            "Coal Mine Operator":"The Marshall County Coal Company",
            "Controller":"Robert E. Murray",
            "County":"Marshall",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":11718744,
            "Latest annual methane emissions (tCO2e) (Low)":1945981,
            "Latest annual methane emissions (tCO2e) (High)":1945981,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/5/2016",
            "Abandonment Date":"n/a",
            "Address":" 57 Goshorn Woods Road Cameron, WV, 26033 ",
            "Destruction devices used":"Engine (1), Thermal oxider (3)",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.1290523,37.3153363 ]
            },
            "properties": {
            "Mine Name":"Mine No. 39",
            "MSHA ID Number":"4609261",
            "Coal Mine Operator":"XMV, Inc.",
            "Controller":"ArcelorMittal",
            "County":"Mcdowell",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":459680,
            "Latest annual methane emissions (tCO2e) (Low)":4609261,
            "Latest annual methane emissions (tCO2e) (High)":4609261,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/24/2008",
            "Abandonment Date":"n/a",
            "Address":"640 Cloverdew Dairy Road Princeton, WV, 24740 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.1181939,37.7703499 ]
            },
            "properties": {
            "Mine Name":"Ruby Energy",
            "MSHA ID Number":"4608808",
            "Coal Mine Operator":"Spartan Mining Company LLC",
            "Controller":"Contura Energy Inc.",
            "County":"Mingo",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":1015969,
            "Latest annual methane emissions (tCO2e) (Low)":49250,
            "Latest annual methane emissions (tCO2e) (High)":49250,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"11/1/1999",
            "Abandonment Date":"n/a",
            "Address":"170 Scarlet Rd Delbarton, WV, 25670 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.265275,39.671403 ]
            },
            "properties": {
            "Mine Name":"Federal No. 2",
            "MSHA ID Number":"4601456",
            "Coal Mine Operator":"Phoenix Federal No 2 Mining, LLC.",
            "Controller":"John F Hale Jr.",
            "County":"Monongalia",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Non-Producing Active",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":514713,
            "Latest annual methane emissions (tCO2e) (High)":514713,
            "Year of latest emissions data":"2016",
            "Operator Start Date":"6/7/2018",
            "Abandonment Date":"n/a",
            "Address":"1044 Miracle Run Road",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.2475787,39.5925816 ]
            },
            "properties": {
            "Mine Name":"Monongalia County Mine",
            "MSHA ID Number":"4601968",
            "Coal Mine Operator":"The Monongalia County Coal Company",
            "Controller":"Robert E. Murray",
            "County":"Monongalia",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":4469056,
            "Latest annual methane emissions (tCO2e) (Low)":1671787,
            "Latest annual methane emissions (tCO2e) (High)":1671787,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/4/2016",
            "Abandonment Date":"n/a",
            "Address":"Fairview, WV, 26570",
            "Destruction devices used":"Engine",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.000048,38.3014494 ]
            },
            "properties": {
            "Mine Name":"Jerry Fork Eagle",
            "MSHA ID Number":"4608787",
            "Coal Mine Operator":"Nicholas Contura LLC",
            "Controller":"Contura Energy Inc.",
            "County":"Nicholas",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":559834,
            "Latest annual methane emissions (tCO2e) (Low)":4608787,
            "Latest annual methane emissions (tCO2e) (High)":4608787,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"7/26/2016",
            "Abandonment Date":"n/a",
            "Address":"#3 Jerry Fork Rd Drennen, WV, 26667 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.5877037,40.1188576 ]
            },
            "properties": {
            "Mine Name":"Tunnel Ridge Mine",
            "MSHA ID Number":"4608864",
            "Coal Mine Operator":"Tunnel Ridge LLC",
            "Controller":"Alliance Resource Partners LP",
            "County":"Ohio",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":7330458,
            "Latest annual methane emissions (tCO2e) (Low)":427959,
            "Latest annual methane emissions (tCO2e) (High)":427959,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"11/1/2000",
            "Abandonment Date":"n/a",
            "Address":" 2596 Battle Run Road Triadelphia, WV, 26059 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.2661176,37.7790023 ]
            },
            "properties": {
            "Mine Name":"Beckley Pocahontas Mine",
            "MSHA ID Number":"4605252",
            "Coal Mine Operator":"ICG Beckley LLC",
            "Controller":"Arch Resources Inc.",
            "County":"Raleigh",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":1040517,
            "Latest annual methane emissions (tCO2e) (Low)":837826,
            "Latest annual methane emissions (tCO2e) (High)":837826,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/20/2006",
            "Abandonment Date":"n/a",
            "Address":"2221 Old Eccles Road Eccles, WV, 25836 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.2331007,37.7048086 ]
            },
            "properties": {
            "Mine Name":"Affinity Mine",
            "MSHA ID Number":"4608878",
            "Coal Mine Operator":"Pocahontas Coal Company LLC",
            "Controller":"Metinvest BV",
            "County":"Raleigh",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":940892,
            "Latest annual methane emissions (tCO2e) (Low)":261975,
            "Latest annual methane emissions (tCO2e) (High)":261975,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"6/10/2011",
            "Abandonment Date":"n/a",
            "Address":"111 Affinity Complex Road Sophia, WV, 25921 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.9760296,39.3403361 ]
            },
            "properties": {
            "Mine Name":"Leer Mine",
            "MSHA ID Number":"4609192",
            "Coal Mine Operator":"ACI Tygart Valley",
            "Controller":"Arch Resources Inc.",
            "County":"Taylor",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":4274748,
            "Latest annual methane emissions (tCO2e) (Low)":543817,
            "Latest annual methane emissions (tCO2e) (High)":543817,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/23/2007",
            "Abandonment Date":"n/a",
            "Address":"1200 Tygart Drive Grafton, WV, 26354 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.5370499,37.5831684 ]
            },
            "properties": {
            "Mine Name":"Wyoming No. 2",
            "MSHA ID Number":"4606263",
            "Coal Mine Operator":"Brooks Run South Mining LLC",
            "Controller":"Contura Energy Inc.",
            "County":"Wyoming",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":222238,
            "Latest annual methane emissions (tCO2e) (Low)":20841,
            "Latest annual methane emissions (tCO2e) (High)":20841,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"1/20/2014",
            "Abandonment Date":"n/a",
            "Address":"Pineville, WV, 24874",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.8776191,37.7398294 ]
            },
            "properties": {
            "Mine Name":"Lower War Eagle",
            "MSHA ID Number":"4609319",
            "Coal Mine Operator":"Greenbrier Minerals LLC",
            "Controller":"Coronado Coal LLC",
            "County":"Wyoming",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":695862,
            "Latest annual methane emissions (tCO2e) (Low)":157045,
            "Latest annual methane emissions (tCO2e) (High)":157045,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"12/31/2014",
            "Abandonment Date":"n/a",
            "Address":"PO Box 446 Man, WV, 25635 ",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EIA"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.5370499,37.5831684 ]
            },
            "properties": {
            "Mine Name":"Road Fork No. 51 Mine",
            "MSHA ID Number":"4601544",
            "Coal Mine Operator":"Spartan Mining Company LLC",
            "Controller":"Contura Energy Inc.",
            "County":"Wyoming",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Active",
            "2019 coal production (tons)":502065,
            "Latest annual methane emissions (tCO2e) (Low)":52497,
            "Latest annual methane emissions (tCO2e) (High)":52497,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"9/20/2005",
            "Abandonment Date":"n/a",
            "Address":"Pineville, WV, 24874",
            "Destruction devices used":"",
            "Key Data Sources":"?"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.5183643,37.5663636 ]
            },
            "properties": {
            "Mine Name":"Pinnacle Mine",
            "MSHA ID Number":"2601816",
            "Coal Mine Operator":"Pinnacle Mining Company LLC",
            "Controller":"ERP Compliant Fuels, LLC",
            "County":"Wyoming",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Temporarily Idle",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":668411,
            "Latest annual methane emissions (tCO2e) (High)":668411,
            "Year of latest emissions data":"2018",
            "Operator Start Date":"7/1/2003",
            "Abandonment Date":"n/a",
            "Address":"800 PINNACLE CREEK RD. PINEVILLE, WV, 24874 ",
            "Destruction devices used":"",
            "Key Data Sources":"?"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -107.346,39.1983 ]
            },
            "properties": {
            "Mine Name":"L.S. Wood",
            "MSHA ID Number":"500300",
            "Coal Mine Operator":"Mid-Continent Resources Inc.",
            "Controller":"Mid-Continent Minerals Corp.",
            "County":"Pitkin",
            "State":"CO",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":31955.5,
            "Latest annual methane emissions (tCO2e) (High)":38117.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"12/2/1985",
            "Address":"Pitkin County, CO",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -107.343,39.1894 ]
            },
            "properties": {
            "Mine Name":"Dutch Creek No. 1",
            "MSHA ID Number":"500301",
            "Coal Mine Operator":"Mid-Continent Resources Inc.",
            "Controller":"Mid-Continent Minerals Corp.",
            "County":"Pitkin",
            "State":"CO",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":64315.5,
            "Latest annual methane emissions (tCO2e) (High)":76717.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"10/4/1992",
            "Address":"Pitkin County, CO",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -107.582,39.3147 ]
            },
            "properties": {
            "Mine Name":"Dutch Creek No. 2",
            "MSHA ID Number":"500469",
            "Coal Mine Operator":"Mid-Continent Resources Inc.",
            "Controller":"Mid-Continent Minerals Corp.",
            "County":"Pitkin",
            "State":"CO",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":56225.5,
            "Latest annual methane emissions (tCO2e) (High)":67067.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"7/1/1988",
            "Address":"Pitkin County, CO",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -107.372,39.2167 ]
            },
            "properties": {
            "Mine Name":"Coal Basin",
            "MSHA ID Number":"502342",
            "Coal Mine Operator":"Mid-Continent Resources Inc.",
            "Controller":"Mid-Continent Minerals Corp.",
            "County":"Pitkin",
            "State":"CO",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":21843,
            "Latest annual methane emissions (tCO2e) (High)":26055,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"2/27/1981",
            "Address":"Pitkin County, CO",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -107.636944,38.9125 ]
            },
            "properties": {
            "Mine Name":"Bowie No. 1",
            "MSHA ID Number":"504184",
            "Coal Mine Operator":"Bowie Resources Ltd.",
            "Controller":"Horizon Natural Resources Inc.",
            "County":"Delta",
            "State":"CO",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":24674.5,
            "Latest annual methane emissions (tCO2e) (High)":29432.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"7/20/2004",
            "Address":"Delta County, CO",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -107.553888,38.942222 ]
            },
            "properties": {
            "Mine Name":"Bowie No. 3",
            "MSHA ID Number":"504758",
            "Coal Mine Operator":"Bowie Resources Ltd.",
            "Controller":"Horizon Natural Resources Inc.",
            "County":"Delta",
            "State":"CO",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":17393.5,
            "Latest annual methane emissions (tCO2e) (High)":20747.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"9/30/2005",
            "Address":"Delta County, CO",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.84999847,38.35 ]
            },
            "properties": {
            "Mine Name":"Wabash",
            "MSHA ID Number":"1100877",
            "Coal Mine Operator":"Conservancy Resources LLC",
            "Controller":"Peabody Energy",
            "County":"Wabash",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":63102,
            "Latest annual methane emissions (tCO2e) (High)":75270,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"1/14/2008",
            "Address":"Wabash County, IL",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -88.08999634,38.07 ]
            },
            "properties": {
            "Mine Name":"Pattiki",
            "MSHA ID Number":"1102662",
            "Coal Mine Operator":"White County Coal LLC",
            "Controller":"Alliance Resource Partners LP",
            "County":"White",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":44899.5,
            "Latest annual methane emissions (tCO2e) (High)":53557.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"1/1/2004",
            "Address":"White County, IL",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -88.4625,37.761944 ]
            },
            "properties": {
            "Mine Name":"Big Ridge Portal No. 2",
            "MSHA ID Number":"1102997",
            "Coal Mine Operator":"Big Ridge Inc.",
            "Controller":"Peabody Energy",
            "County":"Saline",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":16989,
            "Latest annual methane emissions (tCO2e) (High)":20265,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"5/1/2002",
            "Address":"Saline County, IL",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -88.383,37.7583 ]
            },
            "properties": {
            "Mine Name":"Willow Lake Portal",
            "MSHA ID Number":"1103054",
            "Coal Mine Operator":"Big Ridge Inc.",
            "Controller":"Peabody Energy",
            "County":"Saline",
            "State":"IL",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":69169.5,
            "Latest annual methane emissions (tCO2e) (High)":82507.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"12/31/2013",
            "Address":"Saline County, IL",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.83000183,37.05 ]
            },
            "properties": {
            "Mine Name":"Ovenfork",
            "MSHA ID Number":"1502055",
            "Coal Mine Operator":"Cumberland River Coal Company",
            "Controller":"Jeffery A. Hoops",
            "County":"Pike",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":21843,
            "Latest annual methane emissions (tCO2e) (High)":26055,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"11/28/1991",
            "Address":"Pike County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.36,37.62 ]
            },
            "properties": {
            "Mine Name":"No. 1",
            "MSHA ID Number":"1507082",
            "Coal Mine Operator":"Freedom Energy Mining Company",
            "Controller":"Alpha Natural Resources Inc.",
            "County":"Pike",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":38832,
            "Latest annual methane emissions (tCO2e) (High)":46320,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"4/24/2014",
            "Address":"Pike County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.35,37.66 ]
            },
            "properties": {
            "Mine Name":"No. 1",
            "MSHA ID Number":"1507475",
            "Coal Mine Operator":"Solid Energy Mining Company",
            "Controller":"Alpha Natural Resources Holdings Inc.",
            "County":"Pike",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":13753,
            "Latest annual methane emissions (tCO2e) (High)":16405,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"4/14/2014",
            "Address":"Pike County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.48,37.6 ]
            },
            "properties": {
            "Mine Name":"No. 3",
            "MSHA ID Number":"1508079",
            "Coal Mine Operator":"Excel Mining LLC",
            "Controller":"Alliance Resource Partners LP",
            "County":"Pike",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":41259,
            "Latest annual methane emissions (tCO2e) (High)":49215,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"9/9/2013",
            "Address":"Pike County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.77999878,37.66 ]
            },
            "properties": {
            "Mine Name":"Camp No. 11",
            "MSHA ID Number":"1508357",
            "Coal Mine Operator":"Peabody Coal Company",
            "Controller":"Patriot Coal Corporation",
            "County":"Union",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":21034,
            "Latest annual methane emissions (tCO2e) (High)":25090,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"3/1/2005",
            "Address":"Union County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.51999664,37.73 ]
            },
            "properties": {
            "Mine Name":"Pontiki No. 1",
            "MSHA ID Number":"1508413",
            "Coal Mine Operator":"Excel Mining LLC",
            "Controller":"Alliance Resource Partners LP",
            "County":"Martin",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":12539.5,
            "Latest annual methane emissions (tCO2e) (High)":14957.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"5/30/2001",
            "Address":"Martin County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.51,37.71 ]
            },
            "properties": {
            "Mine Name":"No. 2",
            "MSHA ID Number":"1509571",
            "Coal Mine Operator":"Excel Mining LLC",
            "Controller":"Alliance Resource Partners LP",
            "County":"Martin",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":17393.5,
            "Latest annual methane emissions (tCO2e) (High)":20747.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"7/29/2009",
            "Address":"Martin County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.88999939,37.51 ]
            },
            "properties": {
            "Mine Name":"Pyro No. 11 Highway",
            "MSHA ID Number":"1510339",
            "Coal Mine Operator":"Pyro Mining Company",
            "Controller":"Costain Group PLC",
            "County":"Union",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":10112.5,
            "Latest annual methane emissions (tCO2e) (High)":12062.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"11/15/1991",
            "Address":"Union County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -87.88999939,37.51 ]
            },
            "properties": {
            "Mine Name":"Pyro No. 9 Slope William Station",
            "MSHA ID Number":"1513881",
            "Coal Mine Operator":"Pyro Mining Company",
            "Controller":"Costain Group PLC",
            "County":"Union",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":16584.5,
            "Latest annual methane emissions (tCO2e) (High)":19782.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"11/15/1991",
            "Address":"Union County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.38,37.67 ]
            },
            "properties": {
            "Mine Name":"No. 1",
            "MSHA ID Number":"1517651",
            "Coal Mine Operator":"Rockhouse Energy Mining",
            "Controller":"Alpha Natural Resources Holdings Inc.",
            "County":"Pike",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":19416,
            "Latest annual methane emissions (tCO2e) (High)":23160,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"9/30/2013",
            "Address":"Pike County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.95999908,37.39 ]
            },
            "properties": {
            "Mine Name":"Jones Fork E-3",
            "MSHA ID Number":"1518589",
            "Coal Mine Operator":"Kentucky Fuel Corporation",
            "Controller":"Jillean L. Justice; James C. Justice III",
            "County":"Knott",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":45708.5,
            "Latest annual methane emissions (tCO2e) (High)":54522.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"5/6/2015",
            "Address":"Knott County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.5100021362,37.71 ]
            },
            "properties": {
            "Mine Name":"Van Lear",
            "MSHA ID Number":"1518839",
            "Coal Mine Operator":"Revelation Energy LLC",
            "Controller":"Jeffery A. Hoops",
            "County":"Martin",
            "State":"KY",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":20629.5,
            "Latest annual methane emissions (tCO2e) (High)":24607.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"4/23/2015",
            "Address":"Martin County, KY",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.01000214,39.59 ]
            },
            "properties": {
            "Mine Name":"Allison / Century",
            "MSHA ID Number":"3301070",
            "Coal Mine Operator":"American Energy Corporation",
            "Controller":"Robert E. Murray",
            "County":"Belmont",
            "State":"OH",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":35596,
            "Latest annual methane emissions (tCO2e) (High)":42460,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/7/2001",
            "Address":"Belmont County, OH",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.83000183,39.8 ]
            },
            "properties": {
            "Mine Name":"Powhatan No. 4",
            "MSHA ID Number":"3301157",
            "Coal Mine Operator":"Quarto Mining Company",
            "Controller":"CONSOL Energy Inc.",
            "County":"Monroe",
            "State":"OH",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":35596,
            "Latest annual methane emissions (tCO2e) (High)":42460,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"5/29/2007",
            "Address":"Monroe County, OH",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -94.645035,34.8622042 ]
            },
            "properties": {
            "Mine Name":"Howe No. 1",
            "MSHA ID Number":"3400145",
            "Coal Mine Operator":"Howe Coal Company",
            "Controller":"Howe Coal Company",
            "County":"Le Flore",
            "State":"OK",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":23865.5,
            "Latest annual methane emissions (tCO2e) (High)":28467.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"2/13/1978",
            "Address":"Le Flore County, OK",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -94.5699996948,35.2 ]
            },
            "properties": {
            "Mine Name":"South Central",
            "MSHA ID Number":"3401787",
            "Coal Mine Operator":"South Central Coal Company Inc.",
            "Controller":"CraKol Energy LLC",
            "County":"Le Flore",
            "State":"OK",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":33573.5,
            "Latest annual methane emissions (tCO2e) (High)":40047.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"8/27/2010",
            "Address":"Le Flore County, OK",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -78.70999908,40.56 ]
            },
            "properties": {
            "Mine Name":"Lancashire No. 20",
            "MSHA ID Number":"3600836",
            "Coal Mine Operator":"Barnes & Tucker Company",
            "Controller":"Allen A. Wenturine",
            "County":"Cambria",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":17798,
            "Latest annual methane emissions (tCO2e) (High)":21230,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"9/9/1988",
            "Address":"Cambria County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -78.69000244,40.51 ]
            },
            "properties": {
            "Mine Name":"Bethlehem No. 32",
            "MSHA ID Number":"3600842",
            "Coal Mine Operator":"Beth Energy Mines Inc.",
            "Controller":"Bethlehem Steel Corp.",
            "County":"Cambria",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":74428,
            "Latest annual methane emissions (tCO2e) (High)":88780,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"9/17/1985",
            "Address":"Cambria County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.98000336,39.76 ]
            },
            "properties": {
            "Mine Name":"Shannopin",
            "MSHA ID Number":"3600907",
            "Coal Mine Operator":"Diversified Energy Ventures Inc.",
            "Controller":"Kenneth R. Eller",
            "County":"Greene",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":34787,
            "Latest annual methane emissions (tCO2e) (High)":41495,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"5/4/2006",
            "Address":"Greene County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.19999695,40.52 ]
            },
            "properties": {
            "Mine Name":"Homer City",
            "MSHA ID Number":"3600926",
            "Coal Mine Operator":"Helen Mining Company",
            "Controller":"Quaker State Corp.",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":36000.5,
            "Latest annual methane emissions (tCO2e) (High)":42942.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/30/1994",
            "Address":"Indiana County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.37999725,40.46 ]
            },
            "properties": {
            "Mine Name":"Marion",
            "MSHA ID Number":"3600929",
            "Coal Mine Operator":"Tunnelton Mining Company",
            "Controller":"Mon Valley Steel Company Inc.",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":10921.5,
            "Latest annual methane emissions (tCO2e) (High)":13027.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"1/16/1997",
            "Address":"Indiana County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.1399993896,40.01 ]
            },
            "properties": {
            "Mine Name":"Marianna No. 58",
            "MSHA ID Number":"3600957",
            "Coal Mine Operator":"ArcelorMittal USA Pristine Resources Inc.",
            "Controller":"ArcelorMittal USA LLC",
            "County":"Washington",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":32360,
            "Latest annual methane emissions (tCO2e) (High)":38600,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"8/31/1988",
            "Address":"Washington County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.02999878,40.1 ]
            },
            "properties": {
            "Mine Name":"Somerset No. 60",
            "MSHA ID Number":"3600959",
            "Coal Mine Operator":"Beth Energy Mines Inc.",
            "Controller":"Bethlehem Steel Corp.",
            "County":"Washington",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":33169,
            "Latest annual methane emissions (tCO2e) (High)":39565,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"7/11/1990",
            "Address":"Washington County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.98000336,40.22 ]
            },
            "properties": {
            "Mine Name":"Mathies",
            "MSHA ID Number":"3600963",
            "Coal Mine Operator":"Mon-View Mining Corp.",
            "Controller":"Joseph P. Tassone",
            "County":"Washington",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":25079,
            "Latest annual methane emissions (tCO2e) (High)":29915,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"5/11/2006",
            "Address":"Washington County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80,39.99 ]
            },
            "properties": {
            "Mine Name":"Clyde",
            "MSHA ID Number":"3600967",
            "Coal Mine Operator":"Interstate Thermal Energy Conversion Corp.",
            "Controller":"Thomas M. Hull; Michael P. Carlow",
            "County":"Washington",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":15775.5,
            "Latest annual methane emissions (tCO2e) (High)":18817.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"11/1/2000",
            "Address":"Washington County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.94999695,40.21 ]
            },
            "properties": {
            "Mine Name":"Maple Creek",
            "MSHA ID Number":"3600970",
            "Coal Mine Operator":"Maple Creek Mining Incorporated",
            "Controller":"Robert E. Murray",
            "County":"Washington",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":31955.5,
            "Latest annual methane emissions (tCO2e) (High)":38117.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"2/8/2006",
            "Address":"Washington County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -78.87000275,40.7 ]
            },
            "properties": {
            "Mine Name":"Greenwich Collieries No. 2",
            "MSHA ID Number":"3602404",
            "Coal Mine Operator":"Greenwich Collieries-Div/Pa Mines Corp",
            "Controller":"Pennsylvania Power & Light Company",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":16584.5,
            "Latest annual methane emissions (tCO2e) (High)":19782.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"3/19/1993",
            "Address":"Indiana County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -78.83999634,40.7 ]
            },
            "properties": {
            "Mine Name":"Greenwich Collieries No. 1",
            "MSHA ID Number":"3602405",
            "Coal Mine Operator":"Greenwich Collieries-Div/Pa Mines Corp",
            "Controller":"Pennsylvania Power & Light Company",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":24270,
            "Latest annual methane emissions (tCO2e) (High)":28950,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"8/1/1988",
            "Address":"Indiana County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.02999878,40.16 ]
            },
            "properties": {
            "Mine Name":"Maple Creek No. 2",
            "MSHA ID Number":"3603425",
            "Coal Mine Operator":"United States Steel Mining Company Inc.",
            "Controller":"Usx Corp.",
            "County":"Washington",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":15775.5,
            "Latest annual methane emissions (tCO2e) (High)":18817.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"1/1/1986",
            "Address":"Washington County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.98999786,39.94 ]
            },
            "properties": {
            "Mine Name":"Dilworth",
            "MSHA ID Number":"3604281",
            "Coal Mine Operator":"Consolidation Coal Company",
            "Controller":"Robert E. Murray",
            "County":"Greene",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":41663.5,
            "Latest annual methane emissions (tCO2e) (High)":49697.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"9/13/2004",
            "Address":"Greene County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.30000305,40.66 ]
            },
            "properties": {
            "Mine Name":"Urling No. 1",
            "MSHA ID Number":"3604852",
            "Coal Mine Operator":"Keystone Coal Mining Company",
            "Controller":"Robert E. Murray",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":19011.5,
            "Latest annual methane emissions (tCO2e) (High)":22677.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"8/21/2001",
            "Address":"Indiana County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.33000183,40.66 ]
            },
            "properties": {
            "Mine Name":"Urling No. 3",
            "MSHA ID Number":"3605658",
            "Coal Mine Operator":"Keystone Coal Mining Company",
            "Controller":"Rochester & Pittsburgh Coal Company",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":10517,
            "Latest annual methane emissions (tCO2e) (High)":12545,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"1/4/1991",
            "Address":"Indiana County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.05999756,40.72 ]
            },
            "properties": {
            "Mine Name":"Tanoma",
            "MSHA ID Number":"3606967",
            "Coal Mine Operator":"Tanoma Mining Company Inc.",
            "Controller":"American Metals & Coal International Inc.",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":15371,
            "Latest annual methane emissions (tCO2e) (High)":18335,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"9/29/2001",
            "Address":"Indiana County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.27999878,40.55 ]
            },
            "properties": {
            "Mine Name":"Lucerne No. 6 Extension",
            "MSHA ID Number":"3607691",
            "Coal Mine Operator":"Helvetia Coal Company",
            "Controller":"CONSOL Energy Inc.",
            "County":"Indiana",
            "State":"PA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":20225,
            "Latest annual methane emissions (tCO2e) (High)":24125,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"5/24/2000",
            "Address":"Indiana County, PA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -110.6100006,39.7 ]
            },
            "properties": {
            "Mine Name":"Soldier Canyon",
            "MSHA ID Number":"4200077",
            "Coal Mine Operator":"Canyon Fuel Company LLC",
            "Controller":"Wolverine Fuels LLC",
            "County":"Carbon",
            "State":"UT",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":40045.5,
            "Latest annual methane emissions (tCO2e) (High)":47767.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"8/26/1999",
            "Address":"Carbon County, UT",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -110.6299973,39.95 ]
            },
            "properties": {
            "Mine Name":"Sunnyside No. 1",
            "MSHA ID Number":"4200093",
            "Coal Mine Operator":"Sunnyside Cogeneration Assoc.",
            "Controller":"Exelon Corporation; American Consumer Industries Inc.",
            "County":"Carbon",
            "State":"UT",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":39641,
            "Latest annual methane emissions (tCO2e) (High)":47285,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"11/15/2002",
            "Address":"Carbon County, UT",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -111.5,40.22 ]
            },
            "properties": {
            "Mine Name":"Castle Gate",
            "MSHA ID Number":"4200165",
            "Coal Mine Operator":"Castle Gate Holding Company",
            "Controller":"Cyprus Amax Minerals Company",
            "County":"Carbon",
            "State":"UT",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":22652,
            "Latest annual methane emissions (tCO2e) (High)":27020,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"10/23/1991",
            "Address":"Carbon County, UT",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -111.5,40.22 ]
            },
            "properties": {
            "Mine Name":"Willow Creek",
            "MSHA ID Number":"4202113",
            "Coal Mine Operator":"Plateau Mining Corporation",
            "Controller":"Rag Aktiengesellschaft",
            "County":"Carbon",
            "State":"UT",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":26292.5,
            "Latest annual methane emissions (tCO2e) (High)":31362.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"8/6/2003",
            "Address":"Carbon County, UT",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.63999939,37.19 ]
            },
            "properties": {
            "Mine Name":"Miles Branch",
            "MSHA ID Number":"4403932",
            "Coal Mine Operator":"Consolidation Coal Company",
            "Controller":"Robert E. Murray",
            "County":"Tazewell",
            "State":"VA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":31955.5,
            "Latest annual methane emissions (tCO2e) (High)":38117.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"3/9/2011",
            "Address":"Tazewell County, VA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.37000275,37.11 ]
            },
            "properties": {
            "Mine Name":"D-R-1",
            "MSHA ID Number":"4404251",
            "Coal Mine Operator":"Dickenson-Russell Coal Co. LLC",
            "Controller":"Alpha Natural Resources Inc.",
            "County":"Dickenson",
            "State":"VA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":67551.5,
            "Latest annual methane emissions (tCO2e) (High)":80577.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"4/8/2009",
            "Address":"Dickenson County, VA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.23000336,37.08 ]
            },
            "properties": {
            "Mine Name":"No. 2",
            "MSHA ID Number":"4404946",
            "Coal Mine Operator":"Black Dog Coal Corporation",
            "Controller":"Alpha Natural Resources Inc.",
            "County":"Dickenson",
            "State":"VA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":30337.5,
            "Latest annual methane emissions (tCO2e) (High)":36187.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"12/31/2009",
            "Address":"Dickenson County, VA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.27999878,37.24 ]
            },
            "properties": {
            "Mine Name":"Cherokee",
            "MSHA ID Number":"4406864",
            "Coal Mine Operator":"Dickenson-Russell Coal Co. LLC",
            "Controller":"Alpha Natural Resources Inc.",
            "County":"Dickenson",
            "State":"VA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":12944,
            "Latest annual methane emissions (tCO2e) (High)":15440,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"3/30/2015",
            "Address":"Dickenson County, VA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -82.37999725,37.38 ]
            },
            "properties": {
            "Mine Name":"Roaring Fork No. 4",
            "MSHA ID Number":"4407146",
            "Coal Mine Operator":"Dickenson-Russell Coal Co. LLC",
            "Controller":"Alpha Natural Resources Inc.",
            "County":"Dickenson",
            "State":"VA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":11326,
            "Latest annual methane emissions (tCO2e) (High)":13510,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"12/8/2014",
            "Address":"Dickenson County, VA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.44000244,37.43 ]
            },
            "properties": {
            "Mine Name":"Keystone No. 1",
            "MSHA ID Number":"4601404",
            "Coal Mine Operator":"Glow Worm Coal Company",
            "Controller":"Melissa Cline",
            "County":"McDowell",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":17393.5,
            "Latest annual methane emissions (tCO2e) (High)":20747.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"4/1/1987",
            "Address":"McDowell County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.55000305,37.43 ]
            },
            "properties": {
            "Mine Name":"Seneca",
            "MSHA ID Number":"4601409",
            "Coal Mine Operator":"United States Steel Mining Company Inc.",
            "Controller":"Usx Corp.",
            "County":"McDowell",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":10112.5,
            "Latest annual methane emissions (tCO2e) (High)":12062.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"3/5/1987",
            "Address":"McDowell County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.080561,39.538952 ]
            },
            "properties": {
            "Mine Name":"No. 93",
            "MSHA ID Number":"4601432",
            "Coal Mine Operator":"Consolidation Coal Company",
            "Controller":"Robert E. Murray",
            "County":"Marion",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":11730.5,
            "Latest annual methane emissions (tCO2e) (High)":13992.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"10/1/1979",
            "Address":"Marion County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.98999786,39.67 ]
            },
            "properties": {
            "Mine Name":"Pursglove No. 15",
            "MSHA ID Number":"4601454",
            "Coal Mine Operator":"Consolidation Coal Company",
            "Controller":"Robert E. Murray",
            "County":"Monongalia",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":31146.5,
            "Latest annual methane emissions (tCO2e) (High)":37152.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"9/14/1989",
            "Address":"Monongalia County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.44999695,37.57 ]
            },
            "properties": {
            "Mine Name":"Itmann No. 3",
            "MSHA ID Number":"4601576",
            "Coal Mine Operator":"Itmann Coal Company",
            "Controller":"CONSOL Energy Inc.",
            "County":"Wyoming",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":25888,
            "Latest annual methane emissions (tCO2e) (High)":30880,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"5/20/1987",
            "Address":"Wyoming County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.37999725,37.76 ]
            },
            "properties": {
            "Mine Name":"Maple Meadow",
            "MSHA ID Number":"4603374",
            "Coal Mine Operator":"Maple Meadow Mining Company",
            "Controller":"Cyprus Amax Minerals Company",
            "County":"Raleigh",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":56225.5,
            "Latest annual methane emissions (tCO2e) (High)":67067.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"12/15/1997",
            "Address":"Raleigh County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.31999969,37.87 ]
            },
            "properties": {
            "Mine Name":"No. 4",
            "MSHA ID Number":"4604388",
            "Coal Mine Operator":"Hansford Smokeless Collieries Inc.",
            "Controller":"Henry Paul Kizer",
            "County":"Raleigh",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":46113,
            "Latest annual methane emissions (tCO2e) (High)":55005,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"1/29/1991",
            "Address":"Raleigh County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.66999817,37.2 ]
            },
            "properties": {
            "Mine Name":"Amonate No. 31",
            "MSHA ID Number":"4604421",
            "Coal Mine Operator":"Consolidation Coal Company",
            "Controller":"Robert E. Murray",
            "County":"Tazewell",
            "State":"VA",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":50562.5,
            "Latest annual methane emissions (tCO2e) (High)":60312.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"9/26/1994",
            "Address":"Tazewell County, VA",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.48999786,37.68 ]
            },
            "properties": {
            "Mine Name":"Beckley No. 2",
            "MSHA ID Number":"4604581",
            "Coal Mine Operator":"Ranger Fuel Corporation",
            "Controller":"Pittston Company",
            "County":"Wyoming",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":25079,
            "Latest annual methane emissions (tCO2e) (High)":29915,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/21/1988",
            "Address":"Wyoming County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.30000305,37.76 ]
            },
            "properties": {
            "Mine Name":"Baylor",
            "MSHA ID Number":"4605592",
            "Coal Mine Operator":"Anker West Virginia Mining Company Inc.",
            "Controller":"Arch Resources Inc.",
            "County":"Raleigh",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":18202.5,
            "Latest annual methane emissions (tCO2e) (High)":21712.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"3/4/2004",
            "Address":"Raleigh County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.298615,39.518117 ]
            },
            "properties": {
            "Mine Name":"Joanne",
            "MSHA ID Number":"4605829",
            "Coal Mine Operator":"Southern Appalachian Coal Company",
            "Controller":"American Electric Power Company Inc.",
            "County":"Marion",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":24674.5,
            "Latest annual methane emissions (tCO2e) (High)":29432.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"3/10/1983",
            "Address":"Marion County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.47000122,37.54 ]
            },
            "properties": {
            "Mine Name":"Shawnee",
            "MSHA ID Number":"4605907",
            "Coal Mine Operator":"United States Steel Mining Company Inc.",
            "Controller":"Usx Corp.",
            "County":"Wyoming",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":14157.5,
            "Latest annual methane emissions (tCO2e) (High)":16887.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"11/7/1994",
            "Address":"Wyoming County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.55000305,37.92 ]
            },
            "properties": {
            "Mine Name":"Upper Big Branch South",
            "MSHA ID Number":"4608436",
            "Coal Mine Operator":"Performance Coal Company",
            "Controller":"Alpha Natural Resources Holdings Inc.",
            "County":"Raleigh",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":33573.5,
            "Latest annual methane emissions (tCO2e) (High)":40047.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"9/26/2012",
            "Address":"Raleigh County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.70999908,37.95 ]
            },
            "properties": {
            "Mine Name":"No. 2",
            "MSHA ID Number":"4608589",
            "Coal Mine Operator":"Dakota LLC",
            "Controller":"Magnum Coal Company",
            "County":"Boone",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":25079,
            "Latest annual methane emissions (tCO2e) (High)":29915,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"4/9/2008",
            "Address":"Boone County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -79.81999969,39.35 ]
            },
            "properties": {
            "Mine Name":"Whitetail Kittanning",
            "MSHA ID Number":"4608751",
            "Coal Mine Operator":"Kingwood Mining Company LLC",
            "Controller":"Alpha Natural Resources Holdings Inc.",
            "County":"Preston",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":47326.5,
            "Latest annual methane emissions (tCO2e) (High)":56452.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/24/2010",
            "Address":"Preston County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.70999908,37.94 ]
            },
            "properties": {
            "Mine Name":"Alma",
            "MSHA ID Number":"4608807",
            "Coal Mine Operator":"Day Mining Inc.",
            "Controller":"Chris Cline",
            "County":"Boone",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":11730.5,
            "Latest annual methane emissions (tCO2e) (High)":13992.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"10/5/2001",
            "Address":"Boone County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -107.763621,38.8531357 ]
            },
            "properties": {
            "Mine Name":"Somerset",
            "MSHA ID Number":"?",
            "Coal Mine Operator":"?",
            "Controller":"?",
            "County":"Delta",
            "State":"CO",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":21034,
            "Latest annual methane emissions (tCO2e) (High)":25090,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"2/16/1989",
            "Address":"Delta County, CO",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -84.3488197,36.172023 ]
            },
            "properties": {
            "Mine Name":"Volunteer No. 1",
            "MSHA ID Number":"?",
            "Coal Mine Operator":"?",
            "Controller":"?",
            "County":"Rosedale",
            "State":"TN",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":35596,
            "Latest annual methane emissions (tCO2e) (High)":42460,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/10/1974",
            "Address":"Rosedale County, TN",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.039441,39.151183 ]
            },
            "properties": {
            "Mine Name":"Kitt No. 1",
            "MSHA ID Number":"?",
            "Coal Mine Operator":"?",
            "Controller":"?",
            "County":"Barbour",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":34787,
            "Latest annual methane emissions (tCO2e) (High)":41495,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/10/1987",
            "Address":"Barbour County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -80.061941,39.193681 ]
            },
            "properties": {
            "Mine Name":"Diamond No. 1",
            "MSHA ID Number":"?",
            "Coal Mine Operator":"?",
            "Controller":"?",
            "County":"Barbour",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":24674.5,
            "Latest annual methane emissions (tCO2e) (High)":29432.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/10/1989",
            "Address":"Barbour County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.647344,37.3860467 ]
            },
            "properties": {
            "Mine Name":"U.S. Steel No. 2",
            "MSHA ID Number":"?",
            "Coal Mine Operator":"?",
            "Controller":"?",
            "County":"McDowell",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":14562,
            "Latest annual methane emissions (tCO2e) (High)":17370,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/10/1991",
            "Address":"McDowell County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.593059,37.369271 ]
            },
            "properties": {
            "Mine Name":"Maitland",
            "MSHA ID Number":"?",
            "Coal Mine Operator":"?",
            "Controller":"?",
            "County":"McDowell",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":10921.5,
            "Latest annual methane emissions (tCO2e) (High)":13027.5,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/10/1982",
            "Address":"McDowell County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.760283,37.95 ]
            },
            "properties": {
            "Mine Name":"Birchfield No. 1",
            "MSHA ID Number":"?",
            "Coal Mine Operator":"?",
            "Controller":"?",
            "County":"Boone",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":25079,
            "Latest annual methane emissions (tCO2e) (High)":29915,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/10/1992",
            "Address":"Boone County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.468058,37.270661 ]
            },
            "properties": {
            "Mine Name":"U.S. Steel No. 14-4",
            "MSHA ID Number":"?",
            "Coal Mine Operator":"?",
            "Controller":"?",
            "County":"McDowell",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Venting)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":16180,
            "Latest annual methane emissions (tCO2e) (High)":19300,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/10/1987",
            "Address":"McDowell County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -110.6168921,39.6479807 ]
            },
            "properties": {
            "Mine Name":"Kennilworth",
            "MSHA ID Number":"?",
            "Coal Mine Operator":"?",
            "Controller":"?",
            "County":"Carbon",
            "State":"UT",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":11326,
            "Latest annual methane emissions (tCO2e) (High)":13510,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/10/1974",
            "Address":"Carbon County, UT",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates":  [ -81.318337,37.865925 ]
            },
            "properties": {
            "Mine Name":"Bonny",
            "MSHA ID Number":"?",
            "Coal Mine Operator":"?",
            "Controller":"?",
            "County":"Raleigh",
            "State":"WV",
            "Country":"US",
            "Mine Status":"Abandoned (Sealed)",
            "2019 coal production (tons)":0,
            "Latest annual methane emissions (tCO2e) (Low)":43686,
            "Latest annual methane emissions (tCO2e) (High)":52110,
            "Year of latest emissions data":"?",
            "Operator Start Date":"?",
            "Abandonment Date":"6/10/1990",
            "Address":"Raleigh County, WV",
            "Destruction devices used":"",
            "Key Data Sources":"MSHA, EPA, Pitchbook"
            }
        }
    ]
}