<template>
    <div id="app">
        <!--<img alt="Vue logo" src="./assets/logo.png">-->
        <MapStory />
    </div>
</template>

<script>
import MapStory from './components/MapStory.vue'

export default {
    name: 'App',
    components: {
        MapStory
    }
}
</script>
