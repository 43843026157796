export default {
    style: 'mapbox://styles/ianlord/ckl9lbiy70are18p6bu10gq6h',
    accessToken: 'pk.eyJ1IjoiaWFubG9yZCIsImEiOiJja2xibXBwM28ybXp6Mm9wZXVwNGlyNWsyIn0.ULeb8H4XodxWlPMBdbl_Ew',
    useTerrain: true,
    showMarkers: false,
    hillshading: {
        'hillshade-exaggeration': 0.25,
        'hillshade-accent-color': '#052547',
        'hillshade-highlight-color': '#63aaff' // or, 978d85
    },
    sky: {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [0.0, 0.0],
        'sky-atmosphere-sun-intensity': 15
    },
    dataColorMap: [
        'match',
        ['get', 'Mine Status'],
        'Active', '#ff0000',
        'Non-Producing Active', '#ff6a00',
        'Temporarily Idle', '#ffa200',
        'Abandoned (Venting)', '#ffcc00',
        'Abandoned (Sealed)', '#ffff00',
        'hsla(0, 0%, 0%, 0)'
    ],
    dataCircleStroke: '#052547',
    dataCircleHeightInterpolation: [
        'interpolate',
        ['exponential', 1],
        ['get', 'Latest annual methane emissions (tCO2e) (High)'],
        10490,
        3,
        2309875.5,
        12,
        4609261,
        21
    ],
    dataExtrusionHeightInterpolation: [
        'interpolate',
        ['exponential', 1],
        ['get', 'Latest annual methane emissions (tCO2e) (High)'],
        10490,
        10,
        2309875.5,
        160,
        4609261,
        535
    ],
    dataFeatureId: 'Mine Name',
    chapters: [
        {
            id: 'title',
            alignment: 'title',
            title: 'Active & Abandoned Mines by Latest Annual Emissions',
            description: 'Perspiciatis earum incidunt ad expedita, laborum necessitatibus. Et odit aperiam libero blanditiis distinctio.',
            location: {
                center: [-105.37890, 38.56234],
                zoom: 3.5,
                pitch: 0.00,
                bearing: 0.00
            },
            onChapterEnter: [
                {
                    layer: 'circles',
                    opacity: 0
                },
                {
                    layer: 'data-driven-circles-labels',
                    opacity: 0
                },
                {
                    layer: 'polygons',
                    opacity: 0
                }
            ],
            onChapterExit: []
        },
        {
            id: 'start',
            alignment: 'left',
            title: 'Lorem ipsum dolor sit, amet consectetur',
            description: 'Perspiciatis earum incidunt ad expedita, laborum necessitatibus. Et odit aperiam libero blanditiis aut error officiis provident voluptas sequi. Voluptate sit nobis dolorum. Aliquam similique nulla quaerat veniam reprehenderit ut consectetur numquam iste labore eum, quae voluptates deleniti repudiandae distinctio rem possimus adipisci ad ullam qui dicta voluptatum quasi eos. Deserunt, obcaecati illum.',
            location: {
                center: [-105.37890, 38.56234],
                zoom: 3.5,
                pitch: 0.00,
                bearing: 0.00
            },
            onChapterEnter: [
                {
                    layer: 'circles',
                    opacity: 0.75
                },
                {
                    layer: 'data-driven-circles-labels',
                    opacity: 0
                },
                {
                    layer: 'polygons',
                    opacity: 0
                }
            ],
            onChapterExit: []
        },
        {
            id: 'ohio',
            alignment: 'left',
            title: 'Officia sint rem obcaecati',
            description: 'Libero quidem officiis nulla deserunt. Aspernatur libero consectetur minus temporibus impedit modi, sint facilis debitis error consequuntur tenetur dolore atque fuga necessitatibus. Quos debitis repellat voluptatum illum ex! Dolor earum, mollitia ut necessitatibus cumque inventore nisi neque doloribus delectus vel aliquid illum ipsa. Omnis quisquam laudantium laboriosam ducimus perspiciatis sit eos suscipit.',
            location: {
                center: [-89.29964, 38.21577],
                zoom: 7.32,
                pitch: 0,
                bearing: 0
            },
            onChapterEnter: [
                {
                    layer: 'data-driven-circles-labels',
                    opacity: 1
                }
            ],
            onChapterExit: []
        },
        {
            id: 'wv',
            alignment: 'left',
            title: 'Quis at non autem fugiat et doloribus',
            description: 'Officiis sequi neque optio vitae, dolore fugiat numquam, similique quaerat magni earum veniam consequuntur necessitatibus, distinctio ab commodi. Tenetur, et. Cum in deleniti officia quis, architecto odit reprehenderit unde rem. Dolorum non accusantium debitis, veniam repellat consequatur blanditiis esse quis, corrupti exercitationem commodi hic quae iusto itaque dolor sint minus accusamus iste dolore ea reprehenderit ratione? Veritatis repudiandae nostrum repellendus?',
            location: {
                center: [-82.63643, 37.65263],
                zoom: 7.85,
                pitch: 0,
                bearing: 0
            },
            onChapterEnter: [],
            onChapterExit: []
        },
        {
            id: 'pa',
            alignment: 'left',
            title: 'Ex vero stiae eaque fugit laudantium',
            description: 'Ratione non sed tempore recusandae ipsa accusantium sequi. Ullam, pariatur! Eligendi ullam delectus perspiciatis tempore recusandae, esse aliquam non minima, quas iste veniam similique! Quibusdam odio error repellat sed enim. Minima possimus asperiores maiores nesciunt velit porro illo? Laborum placeat inventore voluptatibus tempore recusandae sed numquam consequatur, quas pariatur culpa rem modi voluptas magni reprehenderit, voluptates vero qui nesciunt non.',
            location: {
                center: [-80.85356, 39.94053],
                zoom: 7.45,
                pitch: 0,
                bearing: 0
            },
            onChapterEnter: [
                {
                    layer: 'circles',
                    opacity: 0.75
                },
                {
                    layer: 'data-driven-circles-labels',
                    opacity: 1
                },
                {
                    layer: 'polygons',
                    opacity: 0,
                    height: 0
                }
            ],
            onChapterExit: []
        },
        {
            id: 'pa-3d',
            alignment: 'left',
            title: 'Magni asperiores vitae iure',
            description: 'Provident perferendis blanditiis aspernatur eligendi nesciunt quae tempore illum doloribus! Ea libero reprehenderit asperiores dolores iste eveniet optio debitis, fuga corporis voluptatibus impedit voluptates aperiam dignissimos doloribus odit vitae quod. Incidunt laboriosam aspernatur nobis accusantium est veritatis optio quo harum minima at, tenetur autem excepturi cum. A, magnam tenetur neque provident ad blanditiis adipisci quis ratione eveniet dolores vitae unde.',
            location: {
                center: [-82.29731, 38.76683],
                zoom: 6.83,
                pitch: 60.00,
                bearing: -24.80,
                speed: 0.5
            },
            onChapterEnter: [
                {
                    layer: 'circles',
                    opacity: 0
                },
                {
                    layer: 'data-driven-circles-labels',
                    opacity: 0
                },
                {
                    layer: 'polygons',
                    opacity: 0.75
                }
            ],
            onChapterExit: []
        },
        {
            id: 'end',
            alignment: 'left',
            title: 'Explore for yourself!',
            description: 'Perspiciatis earum incidunt ad expedita, laborum necessitatibus. Et odit aperiam libero blanditiis aut error officiis provident voluptas sequi. Voluptate sit nobis dolorum.',
            location: {
                center: [-105.37890, 38.56234],
                zoom: 3.5,
                pitch: 0.00,
                bearing: 0.00
            },
            onChapterEnter: [
                {
                    layer: 'circles',
                    opacity: 0.75
                },
                {
                    layer: 'data-driven-circles-labels',
                    opacity: 0
                },
                {
                    layer: 'polygons',
                    opacity: 0
                }
            ],
            onChapterExit: []
        },
    ]
};
