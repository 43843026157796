<template>
    <div class='mapstory'>
        <div class='map'>
            <div class='map__membrane' ref='map'>

            </div>
        </div>
        <div id='story' ref='story'></div>

        <div class='features'>
            <div 
                v-for='chapter in chapters'
                :key='chapter.id'
                :id='chapter.id'
                class='step'
                ref='step'>

                <div class='step__membrane' 
                    :class='[chapter.alignment || "center", {
                    "hidden": chapter.hidden
                }]'>

                    <h3 v-if='chapter.title' v-html='chapter.title' />
                    <p v-if='chapter.description' v-html='chapter.description' />

                </div>

            </div>
        </div>

        <div class='legend'>
            <div v-for='item in legend' :key='item.key'>
                <div :style='`color: ${item.color}`'>{{ item.key }}</div>
            </div>
        </div>
    </div>
</template>


<script>
import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from 'mapbox-gl';
import scrollama from 'scrollama';
import AnimatedPopup from 'mapbox-gl-animated-popup';
import config from '@/config/config';
import geojson from '@/config/data';

export default {
    props: {
        msg: String
    },
    data: () => ({
        chapters: config.chapters,
        transition: {
            duration: 500
        },

        hoveredFeature: null,
        isPopupHovered: false
    }),
    computed: {
        geojson () {
            // add unique ids to data
            var newJson = {
                "type": "FeatureCollection",
                "features": []
            };

            geojson.features.forEach((feature, i) => {                
                feature.id = i;
                newJson.features.push(feature);
            });

            return newJson;
        },
        geojsonExtrusions () {
            // makes triangular polygons from the point coordinates
            // in the source data; radius is in coordinate degrees
            const radius = 0.15;

            var newJson = {
                "type": "FeatureCollection",
                "features": []
            };

            this.geojson.features.forEach((feature) => {                
                const lat = feature.geometry.coordinates[1];
                const lng = feature.geometry.coordinates[0];
                
                feature.geometry.type = 'Polygon';
                feature.geometry.coordinates = [[
                    [
                        lng + radius, 
                        lat
                    ], [
                        lng + radius * Math.cos(2*Math.PI/3), 
                        lat + radius * Math.sin(2*Math.PI/3)
                    ], [
                        lng + radius * Math.cos(4*Math.PI/3),
                        lat + radius * Math.sin(4*Math.PI/3)
                    ], [
                        lng + radius, 
                        lat
                    ]
                ]];
                
                newJson.features.push(feature);
            });

            return newJson;
        },
        legend () {
            const colors = config.dataColorMap.slice(2, -1);
            var colorArray = [];

            colors.forEach((color, i) => {
                if (i % 2 === 0) {
                    colorArray.push({
                        key: colors[i],
                        color: colors[i + 1]
                    });
                }
            });

            return colorArray;
        }
    },
    mounted () {
        this.setupMap();
        window.addEventListener('resize', this.resize);
        window.addEventListener('orientationchange', this.orientationchange);
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.resize);
        window.removeEventListener('orientationchange', this.orientationchange);
    },
    methods: {
        setupMap () {
            const vm = this;
            mapboxgl.accessToken = config.accessToken;

            this.map = new mapboxgl.Map({
                container: this.$refs.map,
                style: config.style,
                center: config.chapters[0].location.center,
                zoom: config.chapters[0].location.zoom,
                bearing: config.chapters[0].location.bearing,
                pitch: config.chapters[0].location.pitch,
                interactive: false,
                antialias: true,
                optimizeForTerrain: !!config.useTerrain
            });

            if (config.showMarkers) {
                this.marker = new mapboxgl.Marker({ color: config.markerColor });
                this.marker.setLngLat(config.chapters[0].location.center).addTo(this.map);
            }            

            this.map.on('load', function () {
                if (config.useTerrain) {
                    // terrain
                    vm.map.addSource('mapbox-dem', {
                        'type': 'raster-dem',
                        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                        'tileSize': 512,
                        'maxzoom': 14
                    });
                    vm.map.setTerrain({ 
                        'source': 'mapbox-dem', 
                        'exaggeration': 10 
                    });

                    // hillshading
                    vm.map.addSource('dem', {
                        'type': 'raster-dem',
                        'url': 'mapbox://mapbox.terrain-rgb'
                    });
                    vm.map.addLayer({
                        'id': 'hillshading',
                        'source': 'dem',
                        'type': 'hillshade',
                        'paint': config.hillshading
                    }, 'waterway-shadow');

                    // sky
                    vm.map.addLayer({
                        'id': 'sky',
                        'type': 'sky',
                        'paint': config.sky
                    });
                }

                // create hidden popup
                vm.popup = new AnimatedPopup({
                    openingAnimation: {
                        duration: 200,
                        easing: 'easeOutBack'
                    },
                    closingAnimation: {
                        duration: 75,
                        easing: 'easeInBack'
                    },
                    closeButton: false,
                    closeOnClick: false
                });
                
                vm.setupCircles();
                vm.setupExtrusions();
                vm.setupScroller();
            });
        },
        setupCircles () {
            const vm = this;
            
            vm.map.addSource('circles-source', {
                type: 'geojson',
                data: vm.geojson
            });

            vm.map.addLayer({
                'id': 'circles',
                'type': 'circle',
                'source': 'circles-source',
                'paint': {
                    'circle-blur': [
                        'case', ['boolean', ['feature-state', 'hover'], false],
                        0.3, 0.3
                    ],
                    'circle-blur-transition': vm.transition,
                    'circle-color': config.dataColorMap,
                    'circle-opacity': [
                        'case', ['boolean', ['feature-state', 'hover'], false],
                        0.9, 0.7
                    ],
                    'circle-opacity-transition': vm.transition,
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0, [ '*', config.dataCircleHeightInterpolation, 2.9 ],
                        5, [ '*', config.dataCircleHeightInterpolation, 3.19 ],
                        10, [ '*', config.dataCircleHeightInterpolation, 4.06 ],
                        22, [ '*', config.dataCircleHeightInterpolation, 5.8 ]
                    ],
                    'circle-stroke-color': config.dataCircleStroke,
                    'circle-stroke-opacity': 1,
                    'circle-stroke-width': [
                        'case', ['boolean', ['feature-state', 'hover'], false],
                        3, 0
                    ]
                }
            });

            vm.map.on('mousemove', 'circles', function (e) {
                if (e.features.length > 0) {
                    vm.hoverFeature(e.features[0], e.lngLat, e.features[0].geometry.coordinates.slice());
                }
            });

            vm.map.on('mouseleave', 'circles', function () {
                vm.unhoverFeature();
            });
        },
        setupExtrusions () {
            const vm = this;

            vm.map.addSource('polygons-source', {
                type: 'geojson',
                data: vm.geojsonExtrusions
            });

            vm.map.addLayer({
                'id': 'polygons',
                'type': 'fill-extrusion',
                'source': 'polygons-source',
                'paint': {
                    'fill-extrusion-color': config.dataColorMap,
                    'fill-extrusion-height': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0, [ '*', config.dataExtrusionHeightInterpolation, 272 ],
                        5, [ '*', config.dataExtrusionHeightInterpolation, 300 ],
                        10, [ '*', config.dataExtrusionHeightInterpolation, 380 ],
                        22, [ '*', config.dataExtrusionHeightInterpolation, 544 ]
                    ],
                    'fill-extrusion-base': 0,                    
                    'fill-extrusion-opacity': 0.8
                }
            });

            vm.map.on('mousemove', 'polygons', function (e) {
                if (e.features.length > 0) {
                    vm.hoverFeature(e.features[0], e.lngLat, e.features[0].geometry.coordinates[0][1].slice());
                }
            });

            vm.map.on('mouseleave', 'polygons', function () {
                vm.unhoverFeature();
            });
        },
        setupScroller () {
            const vm = this;

            this.scroller = scrollama();

            this.scroller.setup({
                step: vm.$refs.step,
                offset: 0.5,
                progress: true
            }).onStepEnter(response => {
                var chapter = config.chapters.find(chap => chap.id === response.element.id);

                response.element.classList.add('active');

                vm.map[chapter.mapAnimation || 'flyTo'](chapter.location);

                if (config.showMarkers) {
                    this.marker.setLngLat(chapter.location.center);
                }

                if (chapter.onChapterEnter.length > 0) {
                    chapter.onChapterEnter.forEach(vm.setLayerOpacity);
                }

                if (chapter.callback) {
                    window[chapter.callback]();
                }

                if (chapter.rotateAnimation) {
                    vm.map.once('moveend', function() {
                        const rotateNumber = vm.map.getBearing();
                        vm.map.rotateTo(rotateNumber + 90, {
                            duration: 24000, easing: function (t) {
                                return t;
                            }
                        });
                    });
                }
            }).onStepExit(response => {
                var chapter = config.chapters.find(chap => chap.id === response.element.id);
                response.element.classList.remove('active');

                if (chapter.onChapterExit.length > 0) {
                    chapter.onChapterExit.forEach(vm.setLayerOpacity);
                }
            });
        },

        hoverFeature (feature, eventLatLng, popupLatLng) {
            const vm = this;
            const hoveredFeature = feature.id;
            const layer = feature.layer;

            // don't count hover if feature's opacity is zero
            if (layer.paint[layer.type + '-opacity'] === 0) return;

            if (hoveredFeature !== vm.hoveredFeature) {
                // add hover state for conditional paint props
                vm.resetHoveredFeature();

                vm.hoveredFeature = feature.id;
                vm.map.setFeatureState(
                    { source: 'circles-source', id: vm.hoveredFeature },
                    { hover: true }
                );
                vm.map.setFeatureState(
                    { source: 'polygons-source', id: vm.hoveredFeature },
                    { hover: true }
                );

                // popup
                var properties = feature.properties;
                var description = `<div class='popup'><div class='popup__membrane'>`;
                var popupOffset = 0;

                if (layer.type === 'circle') {
                    popupOffset = layer.paint['circle-radius'] * 0.5;
                    vm.popup.options.anchor = null;
                } else if (layer.type === 'fill-extrusion') {
                    popupOffset = [0, 0];
                    vm.popup.options.anchor = 'bottom-right';
                }
                
                Object.keys(properties).forEach(function(key) {
                    const val = properties[key];

                    if (!val || val === 'n/a' || val === '?') return;

                    description += `
                        <div class='property'>
                            <span class='property__key'>${key}</span>
                            <span class='property__val'>${val}</span>
                        </div>
                    `;
                });

                description += `</div></div>`;

                while (Math.abs(eventLatLng.lng - popupLatLng[0]) > 180) {
                    popupLatLng[0] += eventLatLng.lng > popupLatLng[0] ? 360 : -360;
                }

                vm.map.getCanvas().style.cursor = 'pointer';
                
                vm.popup.setLngLat(popupLatLng)
                    .setHTML(description)
                    .setOffset(popupOffset)
                    .addTo(vm.map);

                const popupElement = vm.popup.getElement();
                popupElement.addEventListener('mouseenter', vm.hoverPopup);
                popupElement.addEventListener('mouseleave', vm.unhoverPopup);
            }
        },
        unhoverFeature () {
            this.unhoverFeatureTimeout = setTimeout(() => {
                if (this.isPopupHovered) return;
                this.resetHoveredFeature();
            }, 50);
        },
        resetHoveredFeature () {
            const vm = this;
            const popupElement = vm.popup.getElement();

            if (!vm.hoveredFeature) return;

            if (vm.unhoverFeatureTimeout) {
                clearTimeout(vm.unhoverFeatureTimeout)
            }

            if (typeof popupElement !== 'undefined') {
                popupElement.removeEventListener('mouseenter', vm.hoverPopup);
                popupElement.removeEventListener('mouseleave', vm.unhoverPopup);
            }
            
            vm.map.setFeatureState(
                { source: 'circles-source', id: vm.hoveredFeature },
                { hover: false }
            );
            vm.map.setFeatureState(
                { source: 'polygons-source', id: vm.hoveredFeature },
                { hover: false }
            );

            vm.hoveredFeature = null;
            vm.map.getCanvas().style.cursor = '';
            vm.popup.remove();
        },
        hoverPopup () {
            this.isPopupHovered = true;
        },
        unhoverPopup () {
            this.isPopupHovered = false;
            this.unhoverFeature();
        },

        getLayerPaintType (layer) {
            const layerTypes = {
                'fill': ['fill-opacity'],
                'line': ['line-opacity'],
                'circle': ['circle-opacity', 'circle-stroke-opacity'],
                'symbol': ['icon-opacity', 'text-opacity'],
                'raster': ['raster-opacity'],
                'fill-extrusion': ['fill-extrusion-opacity'],
                'heatmap': ['heatmap-opacity']
            };
            
            var layerType = this.map.getLayer(layer).type;
            return layerTypes[layerType];
        },
        setLayerOpacity (layer) {
            const vm = this;
            var paintProps = this.getLayerPaintType(layer.layer);

            paintProps.forEach(function(prop) {
                var options = {};
                if (layer.duration) {
                    var transitionProp = prop + '-transition';
                    options = { "duration": layer.duration };
                    vm.map.setPaintProperty(layer.layer, transitionProp, options);
                }
                vm.map.setPaintProperty(layer.layer, prop, layer.opacity, options);
            });
        },

        resize () {
            this.scroller.resize();
        },
        orientationchange () {
            this.scroller.resize();
        }
    }
}
</script>